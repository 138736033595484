import { InputTypes, PluginOption } from './options.types'

const options: PluginOption[] = [
  {
    name: 'Timegate',
    description: 'Enable the Timegate integration for shifts and users',
    fieldName: 'timegate.enabled',
    default: false,
    type: InputTypes.Select,
  },
  {
    name: 'Tenant Mapping',
    description: 'Timegate tenant id to map to this Lighthouse application',
    fieldName: 'timegate.options.tenantId',
    default: '',
    type: InputTypes.Text,
    isSubOption: true,
  },
]

export default options
